<template>
  <index-content :breadcrumb-items="breadcrumbItems">
    <template #options>
      <div class="n-button-box">
        <nb-add @on-click="openAdd" />
      </div>
    </template>
    <div class="table-responsive">
      <table class="table table-bordered table-hover table-striped text-center n-table">
        <colgroup>
          <col class="n-table-timestamp" />
          <col class="n-table-timestamp" />
          <col class="login" />
          <col class="trading" />
          <col class="editing" />
          <col class="transfer" />
          <col v-rw class="action-remove" />
        </colgroup>
        <thead>
          <tr>
            <th rowspan="2">{{ $t('label.from') }}</th>
            <th rowspan="2">{{ $t('label.to') }}</th>
            <th colspan="4">{{ $t('label.restriction') }}</th>
            <th v-rw rowspan="2">{{ $t('label.action') }}</th>
          </tr>
          <tr>
            <th class="login">{{ $t('label.login') }}</th>
            <th class="trading">{{ $t('label.trading') }}</th>
            <th class="editing">{{ $t('label.editing') }}</th>
            <th class="transfer">{{ $t('label.transfer') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in records" :key="row.id">
            <td>{{ row.time1 | datetime }}</td>
            <td>{{ row.time2 | datetime }}</td>
            <td v-enums:LoginRestriction="row.loginRestriction"></td>
            <td v-enums:TradingRestriction="row.tradingRestriction"></td>
            <td v-enums:EditingRestriction="row.editingRestriction"></td>
            <td v-enums:TransferRestriction="row.transferRestriction"></td>
            <td v-rw class="n-button-box">
              <nb-confirm text="remove" @on-ok="doRemove(row)"></nb-confirm>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </index-content>
</template>

<script>
import BaseMarketView from './view';
import ModalMarketHoliday from './ModalMarketHoliday';
import { fetch, remove } from '@/api/market/market-market-holiday';

export default BaseMarketView.extend({
  name: 'MarketMarketHoliday',

  data() {
    return {
      preload: true,
    };
  },

  mounted() {
    this.breadcrumbItems = [this.$t(`label.holidays`)];
  },

  methods: {
    doLoad() {
      return fetch();
    },

    doRemove(o) {
      return remove(o);
    },

    openAdd() {
      this.createModal(ModalMarketHoliday, { props: { customTitle: 'Add Holiday' }, on: this });
    },

    parse(r) {
      this.records = r;
    },
  },
});
</script>
