import { $fetch, $post, $put, $delete } from '@/services/axios';

export function fetch() {
  return $fetch(`market/holidays`);
}

export function add(data) {
  return $put(`market/holiday`, data);
}

export function validate(data) {
  return $post(`market/holiday/validate`, data);
}

export function remove(data) {
  return $delete(`market/holiday/${data.id}/${data.version}`);
}
